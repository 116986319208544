@use '../../styles/mixin' as *;
@use '../../styles/variables' as *;

.projects {
  padding: 2rem 0;
  &__header {
    padding: 1rem;
    @include header-typography;
    color: $accent-color;
    font-size: 1.5rem;

    @include desktop {
      padding: 3rem;
      font-size: 2rem;
    }
  }
}

.project {
  @include flex-layout(column, center, center);
  color: white;
  margin-bottom: 2rem;

  @include tablet {
    padding: 2rem 6rem;
    margin-bottom: 0;
  }

  @include desktop {
    padding: 2rem 15rem;
  }

  &__wrapper {
    margin: 0.5rem;
    padding: 1rem;
    box-shadow: rgb(0 0 0 / 40%) 0px 10px 14px -5px;
    border-radius: 10px;
    max-width: 500px;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;

    &:hover,
    &:focus,
    &:after {
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
      -webkit-transform: scale(1.1);
      transform: scale(1.05);
    }

    @include tablet {
      max-width: 1000px;
      @include flex-layout(row, flex-start, center);
    }

    @include desktop {
      max-width: 1280px;
    }
  }

  &__header {
    @include header-typography;
    margin: 1rem 0;

    @include desktop {
      font-size: 1.5rem;
    }
  }

  &__description {
    @include header-typography;
    margin-bottom: 1rem;

    @include desktop {
      font-size: 1.25rem;
    }
  }

  &__buttons {
    @include header-typography;
    @include flex-layout(row, center, center);
  }

  &__link {
    color: $light-background;
    margin: 1rem 1rem 2rem 1rem;
    border: 1px solid white;
    padding: 0.5rem 1rem;
    box-shadow: rgb(0 0 0 / 30%) 0px 4px 14px -1px;
    border-radius: 5px;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;

    &:hover,
    &:active,
    &:focus {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    }
  }

  &__img {
    margin-bottom: 2rem;
    border-radius: 6px;
    padding: 0 1rem;

    @include tablet {
      padding: 1.5rem 1.5rem 0 1.5rem;
    }
  }

  &__title {
    @include header-typography;
    margin-bottom: 1rem;
  }

  &__stack {
    @include flex-layout(row, space-between, center);
  }

  &__icon {
    height: 3rem;
    width: 3rem;

    @include desktop {
      height: 4rem;
      width: 4rem;
    }
  }

  &__left {
    @include tablet {
      width: 50%;
    }

    @include desktop {
      padding: 2rem;
    }
  }

  &__right {
    @include tablet {
      width: 50%;
    }

    @include desktop {
      padding: 2rem;
    }
  }
}
