@use '../../styles/mixin' as *;
@use '../../styles/variables' as *;

.skills-section {
  background-color: $light-background;
  padding-top: 2rem;

  @include desktop {
    margin-bottom: 2rem;
  }

  &__title {
    @include header-typography;
    color: $accent-color;
    padding: 1rem;

    @include tablet {
      padding: 3rem;
      font-size: 2rem;
    }
  }
}

.skills {
  @include flex-layout(row, center, center);
  flex-wrap: wrap;
  max-width: 478px;
  margin: 0 auto;
  position: relative;
  min-height: 800px;
  margin-top: 2rem;

  @include tablet {
    max-width: 768px;
    min-height: 900px;
  }

  @include desktop {
    max-width: 1000px;
  }

  &__container {
    margin: 0.5rem;

    @include tablet {
      margin: 1rem;
    }

    @include desktop {
      margin: 1.5rem;
    }
  }

  &__img {
    height: 7rem;
    width: 5rem;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);

    @include tablet {
      height: 8rem;
      width: 6rem;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      -webkit-transition-duration: 0.5s;
      transition-duration: 0.5s;
    }

    &:hover,
    &:focus,
    &:active {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
      -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
      transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
    }
  }
}

.icon {
  &-javascript {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-typescript {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-html {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-react {
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include desktop {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
    }
  }

  &-nodejs {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-mongodb {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-css {
    position: absolute;
    top: 35%;
    left: 20%;
    transform: translate(-50%, -50%);
  }

  &-scss {
    position: absolute;
    top: 35%;
    left: 80%;
    transform: translate(-50%, -50%);
  }

  &-nextjs {
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translate(-50%, -50%);
  }

  &-redux {
    position: absolute;
    top: 50%;
    left: 80%;
    transform: translate(-50%, -50%);
  }

  &-express {
    position: absolute;
    top: 65%;
    left: 20%;
    transform: translate(-50%, -50%);
  }

  &-mysql {
    position: absolute;
    top: 65%;
    left: 80%;
    transform: translate(-50%, -50%);
  }

  &-firebase {
    position: absolute;
    top: 95%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include desktop {
      position: absolute;
      top: 50%;
      left: 80%;
      transform: translate(150%, -50%);
    }
  }
}
