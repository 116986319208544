@use '../../styles/mixin' as *;

.notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: hsl(265, 19%, 88%);
  background-color: hsl(276, 5%, 20%);
  padding: 0 2rem;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
  position: fixed;
  height: 5rem;
  bottom: 0;
  width: 100%;
  left: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  @include tablet {
    width: 40rem;
    left: calc(50% - 20rem);
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  &__success {
    background-color: #12bd4b;
    color: hsl(276, 5%, 20%);
  }

  &__error {
    background-color: #a10c4a;
  }
}
