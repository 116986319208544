@use '../../styles/mixin' as *;
@use '../../styles/variables' as *;

.about {
  background-color: $light-background;
  padding: 2.5rem;

  @include tablet {
    @include flex-layout(row, start, start);
    height: 100vh;
  }
}

.headshot {
  margin-bottom: 1rem;

  @include tablet {
    width: 55rem;
    margin: 0 2rem;
    height: 100%;
    @include flex-layout(column, center, center);
  }

  @include desktop {
    margin-left: 15rem;
  }

  &__img {
    border-radius: 50%;
    height: 18rem;
    width: 18rem;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin: auto;
    object-fit: cover;
  }
}

.biography {
  @include flex-layout(column, center, flex-start);
  padding: 1rem;

  @include tablet {
    height: 100%;
    padding: 2rem;
  }

  @include desktop {
    padding-right: 15rem;
  }

  &__title {
    @include header-typography;
    color: $accent-color;
    margin-bottom: 1rem;
    position: relative;
    overflow: hidden;

    @include desktop {
      font-size: 1.5rem;
    }
  }

  &__intro {
    @include header-typography;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    line-height: 4rem;
    position: relative;
    overflow: hidden;

    &--name {
      color: $primary-blue;
      white-space: pre;
    }

    @include desktop {
      font-size: 3.5rem;
    }
  }

  &__description {
    @include body-typography;
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    position: relative;
    overflow: hidden;
    font-kerning: none;

    @include desktop {
      font-size: 1.35rem;
      line-height: 2.2rem;
    }
  }

  &__resume {
    @include header-typography;
    text-transform: uppercase;
    background-color: $dark-background;
    color: $primary-blue;
    font-size: 1.2rem;
    padding: 1rem 1.5rem;
    transition: color 0.3s, transform 0.3s, -webkit-transform 0.3s, border 0.3s;
    position: relative;
    overflow: hidden;

    &:hover {
      color: $accent-color;
      border: 2px solid $accent-color;
      transform: scaleX(1.05) scaleY(1.05);
      transition: border 0.3s, color 0.3s, transform 0.3s,
        -webkit-transform 0.3s;
    }
  }
}
