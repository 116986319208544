@use '../../../styles/variables' as *;
@use '../../../styles/mixin' as *;

.contact-footer {
  background-color: $dark-background;
  height: 90px;
  overflow: hidden;
  position: relative;

  @include desktop {
  }
}

.contact-credit {
  &__hand {
    height: 70px;
    width: 120px;
    position: absolute;

    &--right {
      top: -15%;
      right: 50%;
      transform: scaleX(-1) rotate(26deg) translate(-138%, 116%);
    }

    &--left {
      bottom: -25%;
      left: 50%;
      transform: rotate(-22deg) TRANSLATE(-140%, -97%);
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $accent-color;
    @include body-typography;

    &:hover,
    &:active,
    &:active {
      -webkit-animation-name: hvr-buzz;
      animation-name: hvr-buzz;
      -webkit-animation-duration: 0.15s;
      animation-duration: 0.15s;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
    }
  }
}

// @-webkit-keyframes hvr-buzz {
//   50% {
//     -webkit-transform: translateX(1.2px) rotate(1.2deg);
//     transform: translateX(1.2px) rotate(1.2deg);
//   }
//   100% {
//     -webkit-transform: translateX(-1.4px) rotate(-1.2deg);
//     transform: translateX(-1.4px) rotate(-1.3deg);
//   }
// }
// @keyframes hvr-buzz {
//   50% {
//     -webkit-transform: translateX(1.2px) rotate(1.2deg);
//     transform: translateX(1.2px) rotate(1.2deg);
//   }
//   100% {
//     -webkit-transform: translateX(-1.2px) rotate(-1.2deg);
//     transform: translateX(-1.2px) rotate(-1.2deg);
//   }
// }
