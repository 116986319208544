@use './variables' as *;

@mixin list-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin link-reset {
  text-decoration: none;
  color: white;
}

@mixin flex-layout($direction, $justify: flex-start, $align: flex-start) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin transition {
  transition: all 0.5s ease-in-out;
}

@mixin tablet {
  @media (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin header-typography {
  font-family: 'Lora', serif;
  font-weight: 700;
}

@mixin body-typography {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
