@use '../../../styles/mixin' as *;
@use '../../../styles/variables' as *;

.contact {
  height: 20vh;
  @include flex-layout(column, center, center);

  &__header {
    @include header-typography;
    color: $accent-color;
    padding: 2rem;
  }

  &__container {
    @include flex-layout(row, center, center);
  }

  &__img {
    height: 3rem;
    width: 3rem;
    margin: 2rem;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;

    &:hover,
    &:focus,
    &:active {
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
      -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
      transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
    }
  }
}
