@use '../../styles/mixin' as *;
@use '../../styles/variables' as *;

.nav {
  background-color: $dark-background;
  position: absolute;
  right: 2rem;
  top: 2rem;

  @include tablet {
    right: 3rem;
    top: 3rem;
  }

  @include desktop {
    right: 4rem;
    top: 4rem;
  }

  &__title {
    color: $primary-blue;
    @include body-typography;
    font-size: 1rem;

    @include tablet {
      font-size: 2rem;
    }

    @include desktop {
      font-size: 3rem;
    }
  }
}

.landing {
  position: relative;
  overflow: hidden;
  height: 100vh;

  &__author,
  &__periods {
    height: 100vh;
    font-size: 10vw;
  }

  &__author {
    background-color: $dark-background;
    color: $light-background;
    font-size: 17vw;
    @include flex-layout(column, center, center);

    @include tablet {
      font-size: 20vw;
    }

    @include desktop {
    }
  }

  &__periods {
    @include flex-layout(row, center, center);
    background-color: $light-background;
    font-size: 40vw;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  &__period {
    width: 2rem;
    height: 2rem;
    background-color: $dark-background;
    border-radius: 50%;
    margin: 0 1rem;
    display: inline-block;

    @include tablet {
      margin: 0 3rem;
      width: 5vw;
      height: 5vw;
    }
  }

  &__name {
    @include header-typography;
    letter-spacing: -0.2vw;
    line-height: 90%;

    @include desktop {
      font-size: 16rem;
      font-weight: 400;
      line-height: 13rem;
    }

    @include flex-layout(row, center, center);

    &--top {
      padding: 5rem 4rem 0 0;

      @include tablet {
        padding: 7rem 4rem 0 0;
      }

      @include desktop {
        padding: 9rem 7rem 0 0;
      }
    }

    &--bottom {
      padding: 0 0 5rem 4rem;

      @include tablet {
        padding: 0 0 7rem 4rem;
      }

      @include desktop {
        padding: 0 0 9rem 7rem;
      }
    }
  }

  &__scroll {
    @include body-typography;
    color: $accent-color;
    font-size: 1rem;
    position: absolute;
    left: 2rem;
    bottom: 2rem;

    @include tablet {
      font-size: 2rem;
      left: 3rem;
      bottom: 3rem;
    }

    @include desktop {
      font-size: 3rem;
      left: 4rem;
      bottom: 4rem;
    }
  }

  &__stars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }

  &__star1 {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: 117px 1613px #ffb51c, 1488px 635px #ffb51c, 944px 914px #ffb51c,
      647px 277px #ffb51c, 1792px 1205px #ffb51c, 656px 1517px #ffb51c,
      820px 1839px #ffb51c, 1153px 1400px #ffb51c, 870px 13px #ffb51c,
      550px 702px #ffb51c, 1155px 1056px #ffb51c, 88px 1709px #ffb51c,
      1450px 1090px #ffb51c, 1929px 457px #ffb51c, 1390px 905px #ffb51c,
      1771px 269px #ffb51c, 1741px 669px #ffb51c, 432px 64px #ffb51c,
      563px 996px #ffb51c, 1918px 1873px #ffb51c, 1845px 1211px #ffb51c,
      231px 1503px #ffb51c, 37px 220px #ffb51c, 1970px 495px #ffb51c,
      1812px 925px #ffb51c, 67px 1398px #ffb51c, 535px 279px #ffb51c,
      1837px 829px #ffb51c, 1945px 685px #ffb51c, 1677px 1817px #ffb51c,
      1317px 1415px #ffb51c, 1785px 905px #ffb51c, 1787px 1554px #ffb51c,
      802px 1296px #ffb51c, 512px 1101px #ffb51c, 583px 1364px #ffb51c,
      336px 558px #ffb51c, 979px 334px #ffb51c, 106px 792px #ffb51c,
      204px 34px #ffb51c, 1845px 1763px #ffb51c, 445px 1599px #ffb51c,
      386px 453px #ffb51c, 471px 952px #ffb51c, 1466px 1676px #ffb51c,
      1885px 303px #ffb51c, 51px 1717px #ffb51c, 1211px 299px #ffb51c,
      1546px 1887px #ffb51c, 1067px 33px #ffb51c, 1088px 1326px #ffb51c,
      1938px 760px #ffb51c, 470px 648px #ffb51c, 1213px 269px #ffb51c,
      1767px 78px #ffb51c, 977px 976px #ffb51c, 1926px 175px #ffb51c,
      722px 1512px #ffb51c, 945px 227px #ffb51c, 1811px 99px #ffb51c,
      1912px 1406px #ffb51c, 1602px 1243px #ffb51c, 610px 449px #ffb51c,
      654px 1393px #ffb51c, 1930px 1193px #ffb51c, 258px 1184px #ffb51c,
      89px 265px #ffb51c, 824px 1494px #ffb51c, 1506px 1435px #ffb51c,
      1027px 753px #ffb51c, 1px 1197px #ffb51c, 530px 1161px #ffb51c,
      864px 1555px #ffb51c, 1610px 1604px #ffb51c, 1035px 1114px #ffb51c,
      1456px 133px #ffb51c, 1196px 1253px #ffb51c, 361px 1037px #ffb51c,
      834px 351px #ffb51c, 436px 1676px #ffb51c, 1194px 1007px #ffb51c,
      1141px 647px #ffb51c, 319px 454px #ffb51c, 937px 1769px #ffb51c,
      1872px 1013px #ffb51c, 733px 643px #ffb51c, 1250px 511px #ffb51c,
      189px 296px #ffb51c, 1639px 163px #ffb51c, 1584px 336px #ffb51c,
      1912px 1343px #ffb51c, 1298px 1307px #ffb51c, 1750px 902px #ffb51c,
      1129px 845px #ffb51c, 1899px 1470px #ffb51c, 1427px 232px #ffb51c,
      1391px 838px #ffb51c, 1225px 1819px #ffb51c, 190px 1366px #ffb51c,
      1865px 518px #ffb51c, 203px 1383px #ffb51c, 1455px 614px #ffb51c,
      423px 354px #ffb51c, 1678px 1790px #ffb51c, 241px 608px #ffb51c,
      1089px 730px #ffb51c, 1342px 38px #ffb51c, 1848px 249px #ffb51c,
      1874px 1785px #ffb51c, 1040px 1837px #ffb51c, 751px 261px #ffb51c,
      510px 1975px #ffb51c, 52px 795px #ffb51c, 1786px 1310px #ffb51c,
      498px 712px #ffb51c, 190px 375px #ffb51c, 1341px 722px #ffb51c,
      43px 1394px #ffb51c, 1821px 1687px #ffb51c, 106px 130px #ffb51c,
      1717px 1978px #ffb51c, 168px 151px #ffb51c, 183px 740px #ffb51c,
      945px 1381px #ffb51c, 669px 1170px #ffb51c, 1285px 1816px #ffb51c,
      110px 1217px #ffb51c, 1623px 813px #ffb51c, 869px 647px #ffb51c,
      867px 582px #ffb51c, 735px 1240px #ffb51c, 519px 1896px #ffb51c,
      132px 156px #ffb51c, 1649px 193px #ffb51c, 241px 1109px #ffb51c,
      643px 484px #ffb51c, 574px 1282px #ffb51c, 1952px 564px #ffb51c,
      1978px 145px #ffb51c, 329px 903px #ffb51c, 1674px 617px #ffb51c,
      1978px 558px #ffb51c, 1808px 1715px #ffb51c, 1526px 1238px #ffb51c,
      475px 1330px #ffb51c, 810px 425px #ffb51c, 1709px 634px #ffb51c,
      1658px 336px #ffb51c, 425px 194px #ffb51c, 352px 96px #ffb51c,
      148px 180px #ffb51c, 1139px 1046px #ffb51c, 1809px 1233px #ffb51c,
      1669px 171px #ffb51c, 263px 1394px #ffb51c, 534px 715px #ffb51c,
      396px 1008px #ffb51c, 589px 1445px #ffb51c, 1190px 381px #ffb51c,
      1709px 279px #ffb51c, 520px 891px #ffb51c, 1136px 1867px #ffb51c,
      1280px 1233px #ffb51c, 836px 296px #ffb51c, 1348px 646px #ffb51c,
      1539px 913px #ffb51c, 423px 781px #ffb51c, 1271px 1805px #ffb51c,
      696px 564px #ffb51c, 1549px 804px #ffb51c, 303px 1555px #ffb51c,
      1449px 1903px #ffb51c, 66px 687px #ffb51c, 1164px 856px #ffb51c,
      1958px 1326px #ffb51c, 125px 157px #ffb51c, 508px 1669px #ffb51c,
      465px 725px #ffb51c, 1925px 1440px #ffb51c, 405px 793px #ffb51c,
      278px 110px #ffb51c, 1084px 1065px #ffb51c, 1077px 705px #ffb51c,
      663px 1844px #ffb51c, 734px 263px #ffb51c, 870px 1761px #ffb51c,
      103px 1169px #ffb51c, 1506px 1295px #ffb51c, 1883px 926px #ffb51c,
      335px 1361px #ffb51c, 1126px 1284px #ffb51c, 257px 1165px #ffb51c,
      837px 580px #ffb51c, 1211px 1362px #ffb51c, 1137px 1380px #ffb51c,
      135px 632px #ffb51c, 1491px 1965px #ffb51c, 1098px 195px #ffb51c,
      506px 417px #ffb51c, 693px 1243px #ffb51c, 622px 1862px #ffb51c,
      1412px 1343px #ffb51c, 948px 1894px #ffb51c, 1315px 1363px #ffb51c,
      754px 1098px #ffb51c, 1931px 930px #ffb51c, 1831px 342px #ffb51c,
      1751px 1839px #ffb51c, 84px 775px #ffb51c, 1662px 1488px #ffb51c,
      617px 1769px #ffb51c, 1869px 1292px #ffb51c, 963px 432px #ffb51c,
      371px 1114px #ffb51c, 37px 642px #ffb51c, 21px 1184px #ffb51c,
      602px 366px #ffb51c, 414px 524px #ffb51c, 282px 244px #ffb51c,
      1689px 868px #ffb51c, 943px 681px #ffb51c, 898px 679px #ffb51c,
      449px 1774px #ffb51c, 1678px 1313px #ffb51c, 475px 1811px #ffb51c,
      1146px 1509px #ffb51c, 1151px 1863px #ffb51c, 1617px 846px #ffb51c,
      82px 1077px #ffb51c, 324px 1317px #ffb51c, 1516px 885px #ffb51c,
      1706px 1526px #ffb51c, 1925px 1180px #ffb51c, 553px 967px #ffb51c,
      1072px 536px #ffb51c, 1715px 1816px #ffb51c, 185px 286px #ffb51c,
      1362px 1600px #ffb51c, 628px 1938px #ffb51c, 1187px 412px #ffb51c,
      569px 211px #ffb51c, 1959px 1356px #ffb51c, 1571px 105px #ffb51c,
      319px 1111px #ffb51c, 36px 1364px #ffb51c, 502px 1788px #ffb51c,
      1051px 1993px #ffb51c, 1617px 773px #ffb51c, 424px 1507px #ffb51c,
      1623px 1955px #ffb51c, 307px 662px #ffb51c, 183px 1048px #ffb51c,
      1919px 1453px #ffb51c, 1006px 1817px #ffb51c, 468px 673px #ffb51c,
      1142px 1375px #ffb51c, 1228px 443px #ffb51c, 1734px 552px #ffb51c,
      20px 1041px #ffb51c, 1783px 334px #ffb51c, 98px 1237px #ffb51c,
      1356px 1940px #ffb51c, 853px 1779px #ffb51c, 1910px 560px #ffb51c,
      1174px 1656px #ffb51c, 110px 1724px #ffb51c, 542px 1771px #ffb51c,
      1758px 1931px #ffb51c, 1463px 1401px #ffb51c, 1155px 84px #ffb51c,
      1504px 835px #ffb51c, 750px 322px #ffb51c, 407px 1900px #ffb51c,
      1600px 1141px #ffb51c, 657px 886px #ffb51c, 526px 714px #ffb51c,
      18px 836px #ffb51c, 1546px 1548px #ffb51c, 22px 469px #ffb51c,
      594px 1466px #ffb51c, 1160px 1078px #ffb51c, 627px 1055px #ffb51c,
      195px 699px #ffb51c, 1099px 684px #ffb51c, 530px 551px #ffb51c,
      1160px 1325px #ffb51c, 894px 727px #ffb51c, 1157px 98px #ffb51c,
      136px 1483px #ffb51c, 1875px 1975px #ffb51c, 1803px 566px #ffb51c,
      318px 1073px #ffb51c, 1866px 1656px #ffb51c, 543px 414px #ffb51c,
      719px 474px #ffb51c, 1115px 738px #ffb51c, 353px 875px #ffb51c,
      184px 1938px #ffb51c, 1854px 1534px #ffb51c, 420px 1698px #ffb51c,
      1480px 1550px #ffb51c, 522px 203px #ffb51c, 1897px 1904px #ffb51c,
      975px 1708px #ffb51c, 1774px 602px #ffb51c, 1908px 274px #ffb51c,
      61px 715px #ffb51c, 983px 1156px #ffb51c, 326px 1013px #ffb51c,
      641px 290px #ffb51c, 1522px 120px #ffb51c, 405px 1637px #ffb51c,
      1021px 1099px #ffb51c, 631px 1145px #ffb51c, 982px 1967px #ffb51c,
      200px 651px #ffb51c, 795px 351px #ffb51c, 790px 1082px #ffb51c,
      144px 1572px #ffb51c, 1542px 901px #ffb51c, 158px 1524px #ffb51c,
      849px 1843px #ffb51c, 1807px 203px #ffb51c, 1747px 45px #ffb51c,
      1603px 1738px #ffb51c, 617px 1966px #ffb51c, 342px 748px #ffb51c,
      1779px 1173px #ffb51c, 1428px 152px #ffb51c, 589px 1998px #ffb51c,
      1940px 1838px #ffb51c, 115px 272px #ffb51c, 1217px 1395px #ffb51c,
      1402px 1491px #ffb51c, 1833px 1814px #ffb51c, 243px 966px #ffb51c,
      319px 578px #ffb51c, 813px 364px #ffb51c, 669px 882px #ffb51c,
      551px 134px #ffb51c, 1819px 920px #ffb51c, 740px 1826px #ffb51c,
      1021px 952px #ffb51c, 1575px 453px #ffb51c, 324px 419px #ffb51c,
      929px 417px #ffb51c, 885px 1112px #ffb51c, 503px 187px #ffb51c,
      1908px 362px #ffb51c, 1063px 1601px #ffb51c, 169px 1792px #ffb51c,
      789px 963px #ffb51c, 1697px 948px #ffb51c, 1761px 1810px #ffb51c,
      1844px 1591px #ffb51c, 1709px 949px #ffb51c, 1402px 1396px #ffb51c,
      1037px 225px #ffb51c, 1832px 518px #ffb51c, 1728px 1782px #ffb51c,
      194px 1421px #ffb51c, 1395px 742px #ffb51c, 1478px 1325px #ffb51c,
      40px 593px #ffb51c, 1732px 117px #ffb51c, 51px 158px #ffb51c,
      1598px 1672px #ffb51c, 701px 849px #ffb51c, 1403px 1979px #ffb51c,
      145px 1414px #ffb51c, 550px 906px #ffb51c, 1366px 460px #ffb51c,
      142px 1379px #ffb51c, 34px 1864px #ffb51c, 1346px 308px #ffb51c,
      293px 998px #ffb51c, 21px 1868px #ffb51c, 540px 1033px #ffb51c,
      60px 746px #ffb51c, 1602px 1476px #ffb51c, 180px 804px #ffb51c,
      345px 1982px #ffb51c, 1439px 640px #ffb51c, 939px 1834px #ffb51c,
      20px 432px #ffb51c, 492px 1549px #ffb51c, 109px 1579px #ffb51c,
      1796px 1403px #ffb51c, 1079px 519px #ffb51c, 1664px 389px #ffb51c,
      1627px 1061px #ffb51c, 823px 419px #ffb51c, 1399px 1882px #ffb51c,
      1906px 344px #ffb51c, 1189px 848px #ffb51c, 117px 882px #ffb51c,
      1262px 33px #ffb51c, 1048px 434px #ffb51c, 1208px 1309px #ffb51c,
      1616px 408px #ffb51c, 1833px 853px #ffb51c, 1433px 1656px #ffb51c,
      811px 1861px #ffb51c, 439px 1672px #ffb51c, 1105px 248px #ffb51c,
      328px 1652px #ffb51c, 13px 1658px #ffb51c, 685px 987px #ffb51c,
      985px 403px #ffb51c, 1664px 1206px #ffb51c, 1993px 1925px #ffb51c,
      440px 917px #ffb51c, 1835px 319px #ffb51c, 1404px 1907px #ffb51c,
      624px 1443px #ffb51c, 843px 954px #ffb51c, 478px 1567px #ffb51c,
      895px 1602px #ffb51c, 1231px 871px #ffb51c, 1267px 1646px #ffb51c,
      475px 334px #ffb51c, 784px 796px #ffb51c, 1294px 199px #ffb51c,
      109px 702px #ffb51c, 1978px 362px #ffb51c, 291px 940px #ffb51c,
      971px 1343px #ffb51c, 74px 719px #ffb51c, 36px 715px #ffb51c,
      1007px 1423px #ffb51c, 860px 314px #ffb51c, 631px 177px #ffb51c,
      1900px 1590px #ffb51c, 1239px 1348px #ffb51c, 1346px 1270px #ffb51c,
      1934px 1475px #ffb51c, 1553px 559px #ffb51c, 588px 1969px #ffb51c,
      670px 1269px #ffb51c, 1484px 376px #ffb51c, 20px 1424px #ffb51c,
      1396px 8px #ffb51c, 969px 244px #ffb51c, 1807px 538px #ffb51c,
      1873px 891px #ffb51c, 636px 1142px #ffb51c, 1474px 1562px #ffb51c,
      763px 350px #ffb51c, 663px 700px #ffb51c, 500px 1469px #ffb51c,
      1302px 722px #ffb51c, 181px 291px #ffb51c, 266px 893px #ffb51c,
      1403px 654px #ffb51c, 492px 460px #ffb51c, 1503px 1369px #ffb51c,
      23px 1662px #ffb51c, 349px 333px #ffb51c, 1435px 1017px #ffb51c,
      1441px 705px #ffb51c, 1708px 1446px #ffb51c, 1041px 911px #ffb51c,
      1063px 780px #ffb51c, 1158px 1356px #ffb51c, 767px 1454px #ffb51c,
      1912px 797px #ffb51c, 1731px 1759px #ffb51c, 1378px 1390px #ffb51c,
      1815px 1364px #ffb51c, 960px 270px #ffb51c, 1343px 427px #ffb51c,
      275px 203px #ffb51c, 1319px 1092px #ffb51c, 1455px 770px #ffb51c,
      283px 1503px #ffb51c, 1505px 901px #ffb51c, 1738px 1561px #ffb51c,
      1526px 1935px #ffb51c, 1757px 669px #ffb51c, 1640px 620px #ffb51c,
      1750px 722px #ffb51c, 748px 66px #ffb51c, 1149px 540px #ffb51c,
      159px 953px #ffb51c, 200px 1426px #ffb51c, 515px 1110px #ffb51c,
      1552px 737px #ffb51c, 1094px 1459px #ffb51c, 778px 799px #ffb51c,
      1031px 523px #ffb51c, 743px 1825px #ffb51c, 1100px 882px #ffb51c,
      1088px 1836px #ffb51c, 255px 599px #ffb51c, 67px 1361px #ffb51c,
      247px 1721px #ffb51c, 1722px 346px #ffb51c, 1822px 155px #ffb51c,
      452px 1973px #ffb51c, 415px 1960px #ffb51c, 1109px 57px #ffb51c,
      273px 1392px #ffb51c, 404px 1071px #ffb51c, 1212px 353px #ffb51c,
      370px 460px #ffb51c, 795px 1523px #ffb51c, 1932px 340px #ffb51c,
      51px 1473px #ffb51c, 1268px 364px #ffb51c, 1512px 1862px #ffb51c,
      1678px 1801px #ffb51c, 1796px 579px #ffb51c, 254px 251px #ffb51c,
      1466px 1717px #ffb51c, 893px 379px #ffb51c, 1153px 923px #ffb51c,
      913px 1808px #ffb51c, 791px 789px #ffb51c, 417px 1924px #ffb51c,
      1336px 1599px #ffb51c, 1695px 908px #ffb51c, 1120px 114px #ffb51c,
      493px 1949px #ffb51c, 68px 1905px #ffb51c, 969px 481px #ffb51c,
      1420px 1095px #ffb51c, 800px 1117px #ffb51c, 390px 234px #ffb51c,
      356px 1644px #ffb51c, 1098px 1486px #ffb51c, 1360px 521px #ffb51c,
      149px 1198px #ffb51c, 354px 747px #ffb51c, 1749px 487px #ffb51c,
      470px 76px #ffb51c, 1672px 289px #ffb51c, 1731px 545px #ffb51c,
      1547px 1590px #ffb51c, 498px 692px #ffb51c, 398px 1592px #ffb51c,
      1846px 1237px #ffb51c, 1537px 1474px #ffb51c, 1726px 1374px #ffb51c,
      1922px 858px #ffb51c, 376px 321px #ffb51c, 985px 227px #ffb51c,
      234px 1421px #ffb51c, 760px 745px #ffb51c, 1990px 1132px #ffb51c,
      1560px 1597px #ffb51c, 338px 1310px #ffb51c, 1924px 1664px #ffb51c,
      547px 1747px #ffb51c, 1639px 1282px #ffb51c, 1202px 337px #ffb51c,
      1985px 779px #ffb51c, 737px 456px #ffb51c, 89px 501px #ffb51c,
      963px 792px #ffb51c, 655px 1447px #ffb51c, 1492px 1994px #ffb51c,
      1171px 254px #ffb51c, 892px 827px #ffb51c, 1735px 442px #ffb51c,
      1474px 1187px #ffb51c, 846px 1518px #ffb51c, 557px 1805px #ffb51c,
      738px 945px #ffb51c, 795px 68px #ffb51c, 663px 1956px #ffb51c,
      1607px 290px #ffb51c, 1524px 15px #ffb51c, 1097px 1911px #ffb51c,
      157px 1939px #ffb51c, 935px 1065px #ffb51c, 1809px 1708px #ffb51c,
      164px 1157px #ffb51c, 83px 855px #ffb51c, 625px 501px #ffb51c,
      814px 398px #ffb51c, 552px 695px #ffb51c, 597px 1546px #ffb51c,
      1237px 1417px #ffb51c, 628px 284px #ffb51c, 866px 767px #ffb51c,
      1403px 1394px #ffb51c, 765px 1563px #ffb51c, 1648px 109px #ffb51c,
      1205px 1659px #ffb51c, 921px 1313px #ffb51c, 1319px 243px #ffb51c,
      18px 125px #ffb51c, 7px 777px #ffb51c, 181px 418px #ffb51c,
      1062px 1892px #ffb51c, 382px 106px #ffb51c, 994px 751px #ffb51c,
      964px 234px #ffb51c, 40px 118px #ffb51c, 278px 706px #ffb51c,
      1540px 1978px #ffb51c, 425px 1661px #ffb51c, 1050px 321px #ffb51c,
      735px 1729px #ffb51c, 1438px 260px #ffb51c, 1229px 1109px #ffb51c,
      186px 1041px #ffb51c, 244px 1184px #ffb51c, 392px 1472px #ffb51c,
      670px 1249px #ffb51c, 1260px 1443px #ffb51c, 1977px 1511px #ffb51c,
      1240px 773px #ffb51c, 303px 513px #ffb51c, 63px 1530px #ffb51c,
      610px 792px #ffb51c, 1987px 1647px #ffb51c, 676px 1597px #ffb51c,
      1740px 1244px #ffb51c, 816px 1661px #ffb51c, 351px 802px #ffb51c,
      252px 1082px #ffb51c, 31px 365px #ffb51c, 1453px 984px #ffb51c,
      667px 1233px #ffb51c, 1247px 1800px #ffb51c, 839px 270px #ffb51c,
      775px 913px #ffb51c, 1966px 1398px #ffb51c, 499px 813px #ffb51c,
      922px 1982px #ffb51c, 1409px 1902px #ffb51c, 1499px 1766px #ffb51c,
      721px 899px #ffb51c, 788px 807px #ffb51c, 989px 1355px #ffb51c,
      1248px 1274px #ffb51c, 849px 1091px #ffb51c, 1799px 1036px #ffb51c,
      1486px 700px #ffb51c, 170px 1989px #ffb51c, 1275px 799px #ffb51c,
      772px 2000px #ffb51c, 1642px 362px #ffb51c, 216px 940px #ffb51c,
      1893px 281px #ffb51c, 1944px 1298px #ffb51c, 1294px 400px #ffb51c,
      1523px 441px #ffb51c, 1829px 340px #ffb51c, 468px 170px #ffb51c,
      1099px 967px #ffb51c, 1331px 665px #ffb51c, 1174px 1553px #ffb51c,
      1567px 325px #ffb51c, 1028px 1399px #ffb51c, 781px 1451px #ffb51c,
      1912px 1954px #ffb51c, 874px 873px #ffb51c, 1298px 1722px #ffb51c,
      1879px 706px #ffb51c, 57px 1221px #ffb51c, 1116px 1432px #ffb51c,
      48px 811px #ffb51c, 101px 916px #ffb51c, 677px 304px #ffb51c,
      1203px 639px #ffb51c, 1391px 199px #ffb51c, 1895px 1988px #ffb51c,
      1462px 1023px #ffb51c, 1216px 1751px #ffb51c, 1261px 663px #ffb51c,
      1290px 1119px #ffb51c, 137px 1793px #ffb51c, 1052px 1470px #ffb51c,
      1561px 226px #ffb51c, 1156px 402px #ffb51c, 709px 693px #ffb51c,
      1040px 1911px #ffb51c, 1624px 1115px #ffb51c, 551px 475px #ffb51c,
      416px 1090px #ffb51c, 1183px 451px #ffb51c, 58px 765px #ffb51c,
      743px 1016px #ffb51c, 198px 369px #ffb51c, 1645px 1503px #ffb51c,
      997px 22px #ffb51c, 1447px 1323px #ffb51c, 379px 883px #ffb51c,
      1171px 1195px #ffb51c, 919px 133px #ffb51c, 1400px 517px #ffb51c,
      725px 804px #ffb51c, 1600px 699px #ffb51c, 357px 581px #ffb51c,
      266px 1713px #ffb51c, 848px 1749px #ffb51c, 1963px 1045px #ffb51c,
      119px 1136px #ffb51c;
    -webkit-animation: animStar 50s linear infinite;
    -moz-animation: animStar 50s linear infinite;
    -ms-animation: animStar 50s linear infinite;
    animation: animStar 50s linear infinite;

    &:after {
      content: ' ';
      position: absolute;
      top: 2000px;
      width: 1px;
      height: 1px;
      background: transparent;
      box-shadow: 117px 1613px #ffb51c, 1488px 635px #ffb51c,
        944px 914px #ffb51c, 647px 277px #ffb51c, 1792px 1205px #ffb51c,
        656px 1517px #ffb51c, 820px 1839px #ffb51c, 1153px 1400px #ffb51c,
        870px 13px #ffb51c, 550px 702px #ffb51c, 1155px 1056px #ffb51c,
        88px 1709px #ffb51c, 1450px 1090px #ffb51c, 1929px 457px #ffb51c,
        1390px 905px #ffb51c, 1771px 269px #ffb51c, 1741px 669px #ffb51c,
        432px 64px #ffb51c, 563px 996px #ffb51c, 1918px 1873px #ffb51c,
        1845px 1211px #ffb51c, 231px 1503px #ffb51c, 37px 220px #ffb51c,
        1970px 495px #ffb51c, 1812px 925px #ffb51c, 67px 1398px #ffb51c,
        535px 279px #ffb51c, 1837px 829px #ffb51c, 1945px 685px #ffb51c,
        1677px 1817px #ffb51c, 1317px 1415px #ffb51c, 1785px 905px #ffb51c,
        1787px 1554px #ffb51c, 802px 1296px #ffb51c, 512px 1101px #ffb51c,
        583px 1364px #ffb51c, 336px 558px #ffb51c, 979px 334px #ffb51c,
        106px 792px #ffb51c, 204px 34px #ffb51c, 1845px 1763px #ffb51c,
        445px 1599px #ffb51c, 386px 453px #ffb51c, 471px 952px #ffb51c,
        1466px 1676px #ffb51c, 1885px 303px #ffb51c, 51px 1717px #ffb51c,
        1211px 299px #ffb51c, 1546px 1887px #ffb51c, 1067px 33px #ffb51c,
        1088px 1326px #ffb51c, 1938px 760px #ffb51c, 470px 648px #ffb51c,
        1213px 269px #ffb51c, 1767px 78px #ffb51c, 977px 976px #ffb51c,
        1926px 175px #ffb51c, 722px 1512px #ffb51c, 945px 227px #ffb51c,
        1811px 99px #ffb51c, 1912px 1406px #ffb51c, 1602px 1243px #ffb51c,
        610px 449px #ffb51c, 654px 1393px #ffb51c, 1930px 1193px #ffb51c,
        258px 1184px #ffb51c, 89px 265px #ffb51c, 824px 1494px #ffb51c,
        1506px 1435px #ffb51c, 1027px 753px #ffb51c, 1px 1197px #ffb51c,
        530px 1161px #ffb51c, 864px 1555px #ffb51c, 1610px 1604px #ffb51c,
        1035px 1114px #ffb51c, 1456px 133px #ffb51c, 1196px 1253px #ffb51c,
        361px 1037px #ffb51c, 834px 351px #ffb51c, 436px 1676px #ffb51c,
        1194px 1007px #ffb51c, 1141px 647px #ffb51c, 319px 454px #ffb51c,
        937px 1769px #ffb51c, 1872px 1013px #ffb51c, 733px 643px #ffb51c,
        1250px 511px #ffb51c, 189px 296px #ffb51c, 1639px 163px #ffb51c,
        1584px 336px #ffb51c, 1912px 1343px #ffb51c, 1298px 1307px #ffb51c,
        1750px 902px #ffb51c, 1129px 845px #ffb51c, 1899px 1470px #ffb51c,
        1427px 232px #ffb51c, 1391px 838px #ffb51c, 1225px 1819px #ffb51c,
        190px 1366px #ffb51c, 1865px 518px #ffb51c, 203px 1383px #ffb51c,
        1455px 614px #ffb51c, 423px 354px #ffb51c, 1678px 1790px #ffb51c,
        241px 608px #ffb51c, 1089px 730px #ffb51c, 1342px 38px #ffb51c,
        1848px 249px #ffb51c, 1874px 1785px #ffb51c, 1040px 1837px #ffb51c,
        751px 261px #ffb51c, 510px 1975px #ffb51c, 52px 795px #ffb51c,
        1786px 1310px #ffb51c, 498px 712px #ffb51c, 190px 375px #ffb51c,
        1341px 722px #ffb51c, 43px 1394px #ffb51c, 1821px 1687px #ffb51c,
        106px 130px #ffb51c, 1717px 1978px #ffb51c, 168px 151px #ffb51c,
        183px 740px #ffb51c, 945px 1381px #ffb51c, 669px 1170px #ffb51c,
        1285px 1816px #ffb51c, 110px 1217px #ffb51c, 1623px 813px #ffb51c,
        869px 647px #ffb51c, 867px 582px #ffb51c, 735px 1240px #ffb51c,
        519px 1896px #ffb51c, 132px 156px #ffb51c, 1649px 193px #ffb51c,
        241px 1109px #ffb51c, 643px 484px #ffb51c, 574px 1282px #ffb51c,
        1952px 564px #ffb51c, 1978px 145px #ffb51c, 329px 903px #ffb51c,
        1674px 617px #ffb51c, 1978px 558px #ffb51c, 1808px 1715px #ffb51c,
        1526px 1238px #ffb51c, 475px 1330px #ffb51c, 810px 425px #ffb51c,
        1709px 634px #ffb51c, 1658px 336px #ffb51c, 425px 194px #ffb51c,
        352px 96px #ffb51c, 148px 180px #ffb51c, 1139px 1046px #ffb51c,
        1809px 1233px #ffb51c, 1669px 171px #ffb51c, 263px 1394px #ffb51c,
        534px 715px #ffb51c, 396px 1008px #ffb51c, 589px 1445px #ffb51c,
        1190px 381px #ffb51c, 1709px 279px #ffb51c, 520px 891px #ffb51c,
        1136px 1867px #ffb51c, 1280px 1233px #ffb51c, 836px 296px #ffb51c,
        1348px 646px #ffb51c, 1539px 913px #ffb51c, 423px 781px #ffb51c,
        1271px 1805px #ffb51c, 696px 564px #ffb51c, 1549px 804px #ffb51c,
        303px 1555px #ffb51c, 1449px 1903px #ffb51c, 66px 687px #ffb51c,
        1164px 856px #ffb51c, 1958px 1326px #ffb51c, 125px 157px #ffb51c,
        508px 1669px #ffb51c, 465px 725px #ffb51c, 1925px 1440px #ffb51c,
        405px 793px #ffb51c, 278px 110px #ffb51c, 1084px 1065px #ffb51c,
        1077px 705px #ffb51c, 663px 1844px #ffb51c, 734px 263px #ffb51c,
        870px 1761px #ffb51c, 103px 1169px #ffb51c, 1506px 1295px #ffb51c,
        1883px 926px #ffb51c, 335px 1361px #ffb51c, 1126px 1284px #ffb51c,
        257px 1165px #ffb51c, 837px 580px #ffb51c, 1211px 1362px #ffb51c,
        1137px 1380px #ffb51c, 135px 632px #ffb51c, 1491px 1965px #ffb51c,
        1098px 195px #ffb51c, 506px 417px #ffb51c, 693px 1243px #ffb51c,
        622px 1862px #ffb51c, 1412px 1343px #ffb51c, 948px 1894px #ffb51c,
        1315px 1363px #ffb51c, 754px 1098px #ffb51c, 1931px 930px #ffb51c,
        1831px 342px #ffb51c, 1751px 1839px #ffb51c, 84px 775px #ffb51c,
        1662px 1488px #ffb51c, 617px 1769px #ffb51c, 1869px 1292px #ffb51c,
        963px 432px #ffb51c, 371px 1114px #ffb51c, 37px 642px #ffb51c,
        21px 1184px #ffb51c, 602px 366px #ffb51c, 414px 524px #ffb51c,
        282px 244px #ffb51c, 1689px 868px #ffb51c, 943px 681px #ffb51c,
        898px 679px #ffb51c, 449px 1774px #ffb51c, 1678px 1313px #ffb51c,
        475px 1811px #ffb51c, 1146px 1509px #ffb51c, 1151px 1863px #ffb51c,
        1617px 846px #ffb51c, 82px 1077px #ffb51c, 324px 1317px #ffb51c,
        1516px 885px #ffb51c, 1706px 1526px #ffb51c, 1925px 1180px #ffb51c,
        553px 967px #ffb51c, 1072px 536px #ffb51c, 1715px 1816px #ffb51c,
        185px 286px #ffb51c, 1362px 1600px #ffb51c, 628px 1938px #ffb51c,
        1187px 412px #ffb51c, 569px 211px #ffb51c, 1959px 1356px #ffb51c,
        1571px 105px #ffb51c, 319px 1111px #ffb51c, 36px 1364px #ffb51c,
        502px 1788px #ffb51c, 1051px 1993px #ffb51c, 1617px 773px #ffb51c,
        424px 1507px #ffb51c, 1623px 1955px #ffb51c, 307px 662px #ffb51c,
        183px 1048px #ffb51c, 1919px 1453px #ffb51c, 1006px 1817px #ffb51c,
        468px 673px #ffb51c, 1142px 1375px #ffb51c, 1228px 443px #ffb51c,
        1734px 552px #ffb51c, 20px 1041px #ffb51c, 1783px 334px #ffb51c,
        98px 1237px #ffb51c, 1356px 1940px #ffb51c, 853px 1779px #ffb51c,
        1910px 560px #ffb51c, 1174px 1656px #ffb51c, 110px 1724px #ffb51c,
        542px 1771px #ffb51c, 1758px 1931px #ffb51c, 1463px 1401px #ffb51c,
        1155px 84px #ffb51c, 1504px 835px #ffb51c, 750px 322px #ffb51c,
        407px 1900px #ffb51c, 1600px 1141px #ffb51c, 657px 886px #ffb51c,
        526px 714px #ffb51c, 18px 836px #ffb51c, 1546px 1548px #ffb51c,
        22px 469px #ffb51c, 594px 1466px #ffb51c, 1160px 1078px #ffb51c,
        627px 1055px #ffb51c, 195px 699px #ffb51c, 1099px 684px #ffb51c,
        530px 551px #ffb51c, 1160px 1325px #ffb51c, 894px 727px #ffb51c,
        1157px 98px #ffb51c, 136px 1483px #ffb51c, 1875px 1975px #ffb51c,
        1803px 566px #ffb51c, 318px 1073px #ffb51c, 1866px 1656px #ffb51c,
        543px 414px #ffb51c, 719px 474px #ffb51c, 1115px 738px #ffb51c,
        353px 875px #ffb51c, 184px 1938px #ffb51c, 1854px 1534px #ffb51c,
        420px 1698px #ffb51c, 1480px 1550px #ffb51c, 522px 203px #ffb51c,
        1897px 1904px #ffb51c, 975px 1708px #ffb51c, 1774px 602px #ffb51c,
        1908px 274px #ffb51c, 61px 715px #ffb51c, 983px 1156px #ffb51c,
        326px 1013px #ffb51c, 641px 290px #ffb51c, 1522px 120px #ffb51c,
        405px 1637px #ffb51c, 1021px 1099px #ffb51c, 631px 1145px #ffb51c,
        982px 1967px #ffb51c, 200px 651px #ffb51c, 795px 351px #ffb51c,
        790px 1082px #ffb51c, 144px 1572px #ffb51c, 1542px 901px #ffb51c,
        158px 1524px #ffb51c, 849px 1843px #ffb51c, 1807px 203px #ffb51c,
        1747px 45px #ffb51c, 1603px 1738px #ffb51c, 617px 1966px #ffb51c,
        342px 748px #ffb51c, 1779px 1173px #ffb51c, 1428px 152px #ffb51c,
        589px 1998px #ffb51c, 1940px 1838px #ffb51c, 115px 272px #ffb51c,
        1217px 1395px #ffb51c, 1402px 1491px #ffb51c, 1833px 1814px #ffb51c,
        243px 966px #ffb51c, 319px 578px #ffb51c, 813px 364px #ffb51c,
        669px 882px #ffb51c, 551px 134px #ffb51c, 1819px 920px #ffb51c,
        740px 1826px #ffb51c, 1021px 952px #ffb51c, 1575px 453px #ffb51c,
        324px 419px #ffb51c, 929px 417px #ffb51c, 885px 1112px #ffb51c,
        503px 187px #ffb51c, 1908px 362px #ffb51c, 1063px 1601px #ffb51c,
        169px 1792px #ffb51c, 789px 963px #ffb51c, 1697px 948px #ffb51c,
        1761px 1810px #ffb51c, 1844px 1591px #ffb51c, 1709px 949px #ffb51c,
        1402px 1396px #ffb51c, 1037px 225px #ffb51c, 1832px 518px #ffb51c,
        1728px 1782px #ffb51c, 194px 1421px #ffb51c, 1395px 742px #ffb51c,
        1478px 1325px #ffb51c, 40px 593px #ffb51c, 1732px 117px #ffb51c,
        51px 158px #ffb51c, 1598px 1672px #ffb51c, 701px 849px #ffb51c,
        1403px 1979px #ffb51c, 145px 1414px #ffb51c, 550px 906px #ffb51c,
        1366px 460px #ffb51c, 142px 1379px #ffb51c, 34px 1864px #ffb51c,
        1346px 308px #ffb51c, 293px 998px #ffb51c, 21px 1868px #ffb51c,
        540px 1033px #ffb51c, 60px 746px #ffb51c, 1602px 1476px #ffb51c,
        180px 804px #ffb51c, 345px 1982px #ffb51c, 1439px 640px #ffb51c,
        939px 1834px #ffb51c, 20px 432px #ffb51c, 492px 1549px #ffb51c,
        109px 1579px #ffb51c, 1796px 1403px #ffb51c, 1079px 519px #ffb51c,
        1664px 389px #ffb51c, 1627px 1061px #ffb51c, 823px 419px #ffb51c,
        1399px 1882px #ffb51c, 1906px 344px #ffb51c, 1189px 848px #ffb51c,
        117px 882px #ffb51c, 1262px 33px #ffb51c, 1048px 434px #ffb51c,
        1208px 1309px #ffb51c, 1616px 408px #ffb51c, 1833px 853px #ffb51c,
        1433px 1656px #ffb51c, 811px 1861px #ffb51c, 439px 1672px #ffb51c,
        1105px 248px #ffb51c, 328px 1652px #ffb51c, 13px 1658px #ffb51c,
        685px 987px #ffb51c, 985px 403px #ffb51c, 1664px 1206px #ffb51c,
        1993px 1925px #ffb51c, 440px 917px #ffb51c, 1835px 319px #ffb51c,
        1404px 1907px #ffb51c, 624px 1443px #ffb51c, 843px 954px #ffb51c,
        478px 1567px #ffb51c, 895px 1602px #ffb51c, 1231px 871px #ffb51c,
        1267px 1646px #ffb51c, 475px 334px #ffb51c, 784px 796px #ffb51c,
        1294px 199px #ffb51c, 109px 702px #ffb51c, 1978px 362px #ffb51c,
        291px 940px #ffb51c, 971px 1343px #ffb51c, 74px 719px #ffb51c,
        36px 715px #ffb51c, 1007px 1423px #ffb51c, 860px 314px #ffb51c,
        631px 177px #ffb51c, 1900px 1590px #ffb51c, 1239px 1348px #ffb51c,
        1346px 1270px #ffb51c, 1934px 1475px #ffb51c, 1553px 559px #ffb51c,
        588px 1969px #ffb51c, 670px 1269px #ffb51c, 1484px 376px #ffb51c,
        20px 1424px #ffb51c, 1396px 8px #ffb51c, 969px 244px #ffb51c,
        1807px 538px #ffb51c, 1873px 891px #ffb51c, 636px 1142px #ffb51c,
        1474px 1562px #ffb51c, 763px 350px #ffb51c, 663px 700px #ffb51c,
        500px 1469px #ffb51c, 1302px 722px #ffb51c, 181px 291px #ffb51c,
        266px 893px #ffb51c, 1403px 654px #ffb51c, 492px 460px #ffb51c,
        1503px 1369px #ffb51c, 23px 1662px #ffb51c, 349px 333px #ffb51c,
        1435px 1017px #ffb51c, 1441px 705px #ffb51c, 1708px 1446px #ffb51c,
        1041px 911px #ffb51c, 1063px 780px #ffb51c, 1158px 1356px #ffb51c,
        767px 1454px #ffb51c, 1912px 797px #ffb51c, 1731px 1759px #ffb51c,
        1378px 1390px #ffb51c, 1815px 1364px #ffb51c, 960px 270px #ffb51c,
        1343px 427px #ffb51c, 275px 203px #ffb51c, 1319px 1092px #ffb51c,
        1455px 770px #ffb51c, 283px 1503px #ffb51c, 1505px 901px #ffb51c,
        1738px 1561px #ffb51c, 1526px 1935px #ffb51c, 1757px 669px #ffb51c,
        1640px 620px #ffb51c, 1750px 722px #ffb51c, 748px 66px #ffb51c,
        1149px 540px #ffb51c, 159px 953px #ffb51c, 200px 1426px #ffb51c,
        515px 1110px #ffb51c, 1552px 737px #ffb51c, 1094px 1459px #ffb51c,
        778px 799px #ffb51c, 1031px 523px #ffb51c, 743px 1825px #ffb51c,
        1100px 882px #ffb51c, 1088px 1836px #ffb51c, 255px 599px #ffb51c,
        67px 1361px #ffb51c, 247px 1721px #ffb51c, 1722px 346px #ffb51c,
        1822px 155px #ffb51c, 452px 1973px #ffb51c, 415px 1960px #ffb51c,
        1109px 57px #ffb51c, 273px 1392px #ffb51c, 404px 1071px #ffb51c,
        1212px 353px #ffb51c, 370px 460px #ffb51c, 795px 1523px #ffb51c,
        1932px 340px #ffb51c, 51px 1473px #ffb51c, 1268px 364px #ffb51c,
        1512px 1862px #ffb51c, 1678px 1801px #ffb51c, 1796px 579px #ffb51c,
        254px 251px #ffb51c, 1466px 1717px #ffb51c, 893px 379px #ffb51c,
        1153px 923px #ffb51c, 913px 1808px #ffb51c, 791px 789px #ffb51c,
        417px 1924px #ffb51c, 1336px 1599px #ffb51c, 1695px 908px #ffb51c,
        1120px 114px #ffb51c, 493px 1949px #ffb51c, 68px 1905px #ffb51c,
        969px 481px #ffb51c, 1420px 1095px #ffb51c, 800px 1117px #ffb51c,
        390px 234px #ffb51c, 356px 1644px #ffb51c, 1098px 1486px #ffb51c,
        1360px 521px #ffb51c, 149px 1198px #ffb51c, 354px 747px #ffb51c,
        1749px 487px #ffb51c, 470px 76px #ffb51c, 1672px 289px #ffb51c,
        1731px 545px #ffb51c, 1547px 1590px #ffb51c, 498px 692px #ffb51c,
        398px 1592px #ffb51c, 1846px 1237px #ffb51c, 1537px 1474px #ffb51c,
        1726px 1374px #ffb51c, 1922px 858px #ffb51c, 376px 321px #ffb51c,
        985px 227px #ffb51c, 234px 1421px #ffb51c, 760px 745px #ffb51c,
        1990px 1132px #ffb51c, 1560px 1597px #ffb51c, 338px 1310px #ffb51c,
        1924px 1664px #ffb51c, 547px 1747px #ffb51c, 1639px 1282px #ffb51c,
        1202px 337px #ffb51c, 1985px 779px #ffb51c, 737px 456px #ffb51c,
        89px 501px #ffb51c, 963px 792px #ffb51c, 655px 1447px #ffb51c,
        1492px 1994px #ffb51c, 1171px 254px #ffb51c, 892px 827px #ffb51c,
        1735px 442px #ffb51c, 1474px 1187px #ffb51c, 846px 1518px #ffb51c,
        557px 1805px #ffb51c, 738px 945px #ffb51c, 795px 68px #ffb51c,
        663px 1956px #ffb51c, 1607px 290px #ffb51c, 1524px 15px #ffb51c,
        1097px 1911px #ffb51c, 157px 1939px #ffb51c, 935px 1065px #ffb51c,
        1809px 1708px #ffb51c, 164px 1157px #ffb51c, 83px 855px #ffb51c,
        625px 501px #ffb51c, 814px 398px #ffb51c, 552px 695px #ffb51c,
        597px 1546px #ffb51c, 1237px 1417px #ffb51c, 628px 284px #ffb51c,
        866px 767px #ffb51c, 1403px 1394px #ffb51c, 765px 1563px #ffb51c,
        1648px 109px #ffb51c, 1205px 1659px #ffb51c, 921px 1313px #ffb51c,
        1319px 243px #ffb51c, 18px 125px #ffb51c, 7px 777px #ffb51c,
        181px 418px #ffb51c, 1062px 1892px #ffb51c, 382px 106px #ffb51c,
        994px 751px #ffb51c, 964px 234px #ffb51c, 40px 118px #ffb51c,
        278px 706px #ffb51c, 1540px 1978px #ffb51c, 425px 1661px #ffb51c,
        1050px 321px #ffb51c, 735px 1729px #ffb51c, 1438px 260px #ffb51c,
        1229px 1109px #ffb51c, 186px 1041px #ffb51c, 244px 1184px #ffb51c,
        392px 1472px #ffb51c, 670px 1249px #ffb51c, 1260px 1443px #ffb51c,
        1977px 1511px #ffb51c, 1240px 773px #ffb51c, 303px 513px #ffb51c,
        63px 1530px #ffb51c, 610px 792px #ffb51c, 1987px 1647px #ffb51c,
        676px 1597px #ffb51c, 1740px 1244px #ffb51c, 816px 1661px #ffb51c,
        351px 802px #ffb51c, 252px 1082px #ffb51c, 31px 365px #ffb51c,
        1453px 984px #ffb51c, 667px 1233px #ffb51c, 1247px 1800px #ffb51c,
        839px 270px #ffb51c, 775px 913px #ffb51c, 1966px 1398px #ffb51c,
        499px 813px #ffb51c, 922px 1982px #ffb51c, 1409px 1902px #ffb51c,
        1499px 1766px #ffb51c, 721px 899px #ffb51c, 788px 807px #ffb51c,
        989px 1355px #ffb51c, 1248px 1274px #ffb51c, 849px 1091px #ffb51c,
        1799px 1036px #ffb51c, 1486px 700px #ffb51c, 170px 1989px #ffb51c,
        1275px 799px #ffb51c, 772px 2000px #ffb51c, 1642px 362px #ffb51c,
        216px 940px #ffb51c, 1893px 281px #ffb51c, 1944px 1298px #ffb51c,
        1294px 400px #ffb51c, 1523px 441px #ffb51c, 1829px 340px #ffb51c,
        468px 170px #ffb51c, 1099px 967px #ffb51c, 1331px 665px #ffb51c,
        1174px 1553px #ffb51c, 1567px 325px #ffb51c, 1028px 1399px #ffb51c,
        781px 1451px #ffb51c, 1912px 1954px #ffb51c, 874px 873px #ffb51c,
        1298px 1722px #ffb51c, 1879px 706px #ffb51c, 57px 1221px #ffb51c,
        1116px 1432px #ffb51c, 48px 811px #ffb51c, 101px 916px #ffb51c,
        677px 304px #ffb51c, 1203px 639px #ffb51c, 1391px 199px #ffb51c,
        1895px 1988px #ffb51c, 1462px 1023px #ffb51c, 1216px 1751px #ffb51c,
        1261px 663px #ffb51c, 1290px 1119px #ffb51c, 137px 1793px #ffb51c,
        1052px 1470px #ffb51c, 1561px 226px #ffb51c, 1156px 402px #ffb51c,
        709px 693px #ffb51c, 1040px 1911px #ffb51c, 1624px 1115px #ffb51c,
        551px 475px #ffb51c, 416px 1090px #ffb51c, 1183px 451px #ffb51c,
        58px 765px #ffb51c, 743px 1016px #ffb51c, 198px 369px #ffb51c,
        1645px 1503px #ffb51c, 997px 22px #ffb51c, 1447px 1323px #ffb51c,
        379px 883px #ffb51c, 1171px 1195px #ffb51c, 919px 133px #ffb51c,
        1400px 517px #ffb51c, 725px 804px #ffb51c, 1600px 699px #ffb51c,
        357px 581px #ffb51c, 266px 1713px #ffb51c, 848px 1749px #ffb51c,
        1963px 1045px #ffb51c, 119px 1136px #ffb51c;
    }
  }

  &__star2 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: 1117px 1306px #ffb51c, 1078px 1783px #ffb51c,
      1179px 1085px #ffb51c, 1145px 920px #ffb51c, 422px 1233px #ffb51c,
      387px 98px #ffb51c, 1153px 637px #ffb51c, 1084px 782px #ffb51c,
      476px 453px #ffb51c, 926px 1306px #ffb51c, 60px 1086px #ffb51c,
      753px 1575px #ffb51c, 272px 1684px #ffb51c, 1285px 750px #ffb51c,
      1416px 1327px #ffb51c, 1931px 473px #ffb51c, 736px 1395px #ffb51c,
      1816px 763px #ffb51c, 438px 879px #ffb51c, 665px 1902px #ffb51c,
      1341px 677px #ffb51c, 1404px 1073px #ffb51c, 100px 597px #ffb51c,
      357px 1689px #ffb51c, 1044px 1342px #ffb51c, 1954px 502px #ffb51c,
      1192px 1308px #ffb51c, 540px 1239px #ffb51c, 1360px 552px #ffb51c,
      89px 752px #ffb51c, 659px 1253px #ffb51c, 62px 517px #ffb51c,
      1375px 1705px #ffb51c, 1343px 1511px #ffb51c, 1659px 1922px #ffb51c,
      1560px 289px #ffb51c, 1362px 1799px #ffb51c, 1886px 1480px #ffb51c,
      1718px 1885px #ffb51c, 824px 738px #ffb51c, 1060px 1370px #ffb51c,
      1781px 1171px #ffb51c, 255px 273px #ffb51c, 1197px 120px #ffb51c,
      213px 7px #ffb51c, 1226px 1920px #ffb51c, 1844px 207px #ffb51c,
      1675px 970px #ffb51c, 1435px 1283px #ffb51c, 37px 353px #ffb51c,
      59px 417px #ffb51c, 921px 1602px #ffb51c, 1549px 1490px #ffb51c,
      638px 1845px #ffb51c, 1328px 198px #ffb51c, 1050px 1149px #ffb51c,
      1884px 711px #ffb51c, 333px 263px #ffb51c, 342px 1508px #ffb51c,
      1388px 1810px #ffb51c, 1377px 1558px #ffb51c, 890px 487px #ffb51c,
      1081px 759px #ffb51c, 890px 1515px #ffb51c, 911px 1284px #ffb51c,
      335px 735px #ffb51c, 1140px 549px #ffb51c, 1239px 1064px #ffb51c,
      226px 71px #ffb51c, 1100px 1278px #ffb51c, 1851px 1805px #ffb51c,
      1370px 1999px #ffb51c, 1008px 1122px #ffb51c, 785px 813px #ffb51c,
      1358px 601px #ffb51c, 1833px 1305px #ffb51c, 1768px 1304px #ffb51c,
      1303px 532px #ffb51c, 860px 598px #ffb51c, 1329px 593px #ffb51c,
      1038px 1088px #ffb51c, 408px 405px #ffb51c, 965px 82px #ffb51c,
      1483px 1438px #ffb51c, 310px 1479px #ffb51c, 1786px 1500px #ffb51c,
      1866px 852px #ffb51c, 18px 1757px #ffb51c, 1473px 1004px #ffb51c,
      1542px 1933px #ffb51c, 633px 1970px #ffb51c, 1334px 1713px #ffb51c,
      175px 28px #ffb51c, 592px 894px #ffb51c, 121px 1162px #ffb51c,
      1601px 1567px #ffb51c, 1095px 657px #ffb51c, 640px 1233px #ffb51c,
      1073px 1255px #ffb51c, 840px 1087px #ffb51c, 718px 250px #ffb51c,
      967px 709px #ffb51c, 731px 239px #ffb51c, 1623px 593px #ffb51c,
      1058px 1820px #ffb51c, 516px 1898px #ffb51c, 666px 12px #ffb51c,
      1997px 1382px #ffb51c, 112px 1690px #ffb51c, 687px 1309px #ffb51c,
      63px 539px #ffb51c, 185px 1897px #ffb51c, 1055px 1691px #ffb51c,
      435px 1517px #ffb51c, 1175px 1119px #ffb51c, 1721px 133px #ffb51c,
      1212px 47px #ffb51c, 166px 18px #ffb51c, 1416px 1652px #ffb51c,
      1409px 1745px #ffb51c, 1357px 1232px #ffb51c, 1677px 1998px #ffb51c,
      448px 1415px #ffb51c, 705px 1736px #ffb51c, 1031px 1466px #ffb51c,
      543px 1651px #ffb51c, 1592px 1888px #ffb51c, 1749px 1175px #ffb51c,
      639px 1114px #ffb51c, 1591px 508px #ffb51c, 759px 1244px #ffb51c,
      824px 380px #ffb51c, 942px 955px #ffb51c, 723px 732px #ffb51c,
      113px 1369px #ffb51c, 203px 1739px #ffb51c, 868px 733px #ffb51c,
      713px 971px #ffb51c, 341px 833px #ffb51c, 762px 824px #ffb51c,
      1359px 310px #ffb51c, 1858px 1349px #ffb51c, 1531px 692px #ffb51c,
      1075px 1512px #ffb51c, 1677px 142px #ffb51c, 1912px 1478px #ffb51c,
      1810px 1078px #ffb51c, 426px 844px #ffb51c, 1426px 588px #ffb51c,
      1909px 654px #ffb51c, 1107px 295px #ffb51c, 1351px 527px #ffb51c,
      1393px 599px #ffb51c, 1379px 1068px #ffb51c, 228px 1846px #ffb51c,
      1271px 374px #ffb51c, 1348px 612px #ffb51c, 7px 1301px #ffb51c,
      1501px 1782px #ffb51c, 1795px 423px #ffb51c, 1475px 1918px #ffb51c,
      1328px 1861px #ffb51c, 1624px 51px #ffb51c, 1791px 672px #ffb51c,
      1594px 1467px #ffb51c, 1655px 1603px #ffb51c, 919px 850px #ffb51c,
      523px 609px #ffb51c, 1196px 207px #ffb51c, 753px 410px #ffb51c,
      686px 1097px #ffb51c, 1570px 133px #ffb51c, 1996px 1137px #ffb51c,
      361px 116px #ffb51c, 1015px 462px #ffb51c, 76px 1143px #ffb51c,
      491px 1818px #ffb51c, 1563px 795px #ffb51c, 982px 1721px #ffb51c,
      831px 1204px #ffb51c, 1737px 589px #ffb51c, 861px 1579px #ffb51c,
      1666px 130px #ffb51c, 698px 1799px #ffb51c, 726px 1519px #ffb51c,
      109px 1208px #ffb51c, 1184px 1057px #ffb51c, 835px 451px #ffb51c,
      896px 594px #ffb51c, 35px 893px #ffb51c, 895px 542px #ffb51c,
      706px 225px #ffb51c, 56px 1040px #ffb51c, 1954px 108px #ffb51c,
      1439px 1423px #ffb51c, 26px 1881px #ffb51c, 802px 1564px #ffb51c,
      273px 708px #ffb51c, 40px 31px #ffb51c, 859px 108px #ffb51c;
    -webkit-animation: animStar 100s linear infinite;
    -moz-animation: animStar 100s linear infinite;
    -ms-animation: animStar 100s linear infinite;
    animation: animStar 100s linear infinite;

    &:after {
      content: ' ';
      position: absolute;
      top: 2000px;
      width: 2px;
      height: 2px;
      background: transparent;
      box-shadow: 1117px 1306px #ffb51c, 1078px 1783px #ffb51c,
        1179px 1085px #ffb51c, 1145px 920px #ffb51c, 422px 1233px #ffb51c,
        387px 98px #ffb51c, 1153px 637px #ffb51c, 1084px 782px #ffb51c,
        476px 453px #ffb51c, 926px 1306px #ffb51c, 60px 1086px #ffb51c,
        753px 1575px #ffb51c, 272px 1684px #ffb51c, 1285px 750px #ffb51c,
        1416px 1327px #ffb51c, 1931px 473px #ffb51c, 736px 1395px #ffb51c,
        1816px 763px #ffb51c, 438px 879px #ffb51c, 665px 1902px #ffb51c,
        1341px 677px #ffb51c, 1404px 1073px #ffb51c, 100px 597px #ffb51c,
        357px 1689px #ffb51c, 1044px 1342px #ffb51c, 1954px 502px #ffb51c,
        1192px 1308px #ffb51c, 540px 1239px #ffb51c, 1360px 552px #ffb51c,
        89px 752px #ffb51c, 659px 1253px #ffb51c, 62px 517px #ffb51c,
        1375px 1705px #ffb51c, 1343px 1511px #ffb51c, 1659px 1922px #ffb51c,
        1560px 289px #ffb51c, 1362px 1799px #ffb51c, 1886px 1480px #ffb51c,
        1718px 1885px #ffb51c, 824px 738px #ffb51c, 1060px 1370px #ffb51c,
        1781px 1171px #ffb51c, 255px 273px #ffb51c, 1197px 120px #ffb51c,
        213px 7px #ffb51c, 1226px 1920px #ffb51c, 1844px 207px #ffb51c,
        1675px 970px #ffb51c, 1435px 1283px #ffb51c, 37px 353px #ffb51c,
        59px 417px #ffb51c, 921px 1602px #ffb51c, 1549px 1490px #ffb51c,
        638px 1845px #ffb51c, 1328px 198px #ffb51c, 1050px 1149px #ffb51c,
        1884px 711px #ffb51c, 333px 263px #ffb51c, 342px 1508px #ffb51c,
        1388px 1810px #ffb51c, 1377px 1558px #ffb51c, 890px 487px #ffb51c,
        1081px 759px #ffb51c, 890px 1515px #ffb51c, 911px 1284px #ffb51c,
        335px 735px #ffb51c, 1140px 549px #ffb51c, 1239px 1064px #ffb51c,
        226px 71px #ffb51c, 1100px 1278px #ffb51c, 1851px 1805px #ffb51c,
        1370px 1999px #ffb51c, 1008px 1122px #ffb51c, 785px 813px #ffb51c,
        1358px 601px #ffb51c, 1833px 1305px #ffb51c, 1768px 1304px #ffb51c,
        1303px 532px #ffb51c, 860px 598px #ffb51c, 1329px 593px #ffb51c,
        1038px 1088px #ffb51c, 408px 405px #ffb51c, 965px 82px #ffb51c,
        1483px 1438px #ffb51c, 310px 1479px #ffb51c, 1786px 1500px #ffb51c,
        1866px 852px #ffb51c, 18px 1757px #ffb51c, 1473px 1004px #ffb51c,
        1542px 1933px #ffb51c, 633px 1970px #ffb51c, 1334px 1713px #ffb51c,
        175px 28px #ffb51c, 592px 894px #ffb51c, 121px 1162px #ffb51c,
        1601px 1567px #ffb51c, 1095px 657px #ffb51c, 640px 1233px #ffb51c,
        1073px 1255px #ffb51c, 840px 1087px #ffb51c, 718px 250px #ffb51c,
        967px 709px #ffb51c, 731px 239px #ffb51c, 1623px 593px #ffb51c,
        1058px 1820px #ffb51c, 516px 1898px #ffb51c, 666px 12px #ffb51c,
        1997px 1382px #ffb51c, 112px 1690px #ffb51c, 687px 1309px #ffb51c,
        63px 539px #ffb51c, 185px 1897px #ffb51c, 1055px 1691px #ffb51c,
        435px 1517px #ffb51c, 1175px 1119px #ffb51c, 1721px 133px #ffb51c,
        1212px 47px #ffb51c, 166px 18px #ffb51c, 1416px 1652px #ffb51c,
        1409px 1745px #ffb51c, 1357px 1232px #ffb51c, 1677px 1998px #ffb51c,
        448px 1415px #ffb51c, 705px 1736px #ffb51c, 1031px 1466px #ffb51c,
        543px 1651px #ffb51c, 1592px 1888px #ffb51c, 1749px 1175px #ffb51c,
        639px 1114px #ffb51c, 1591px 508px #ffb51c, 759px 1244px #ffb51c,
        824px 380px #ffb51c, 942px 955px #ffb51c, 723px 732px #ffb51c,
        113px 1369px #ffb51c, 203px 1739px #ffb51c, 868px 733px #ffb51c,
        713px 971px #ffb51c, 341px 833px #ffb51c, 762px 824px #ffb51c,
        1359px 310px #ffb51c, 1858px 1349px #ffb51c, 1531px 692px #ffb51c,
        1075px 1512px #ffb51c, 1677px 142px #ffb51c, 1912px 1478px #ffb51c,
        1810px 1078px #ffb51c, 426px 844px #ffb51c, 1426px 588px #ffb51c,
        1909px 654px #ffb51c, 1107px 295px #ffb51c, 1351px 527px #ffb51c,
        1393px 599px #ffb51c, 1379px 1068px #ffb51c, 228px 1846px #ffb51c,
        1271px 374px #ffb51c, 1348px 612px #ffb51c, 7px 1301px #ffb51c,
        1501px 1782px #ffb51c, 1795px 423px #ffb51c, 1475px 1918px #ffb51c,
        1328px 1861px #ffb51c, 1624px 51px #ffb51c, 1791px 672px #ffb51c,
        1594px 1467px #ffb51c, 1655px 1603px #ffb51c, 919px 850px #ffb51c,
        523px 609px #ffb51c, 1196px 207px #ffb51c, 753px 410px #ffb51c,
        686px 1097px #ffb51c, 1570px 133px #ffb51c, 1996px 1137px #ffb51c,
        361px 116px #ffb51c, 1015px 462px #ffb51c, 76px 1143px #ffb51c,
        491px 1818px #ffb51c, 1563px 795px #ffb51c, 982px 1721px #ffb51c,
        831px 1204px #ffb51c, 1737px 589px #ffb51c, 861px 1579px #ffb51c,
        1666px 130px #ffb51c, 698px 1799px #ffb51c, 726px 1519px #ffb51c,
        109px 1208px #ffb51c, 1184px 1057px #ffb51c, 835px 451px #ffb51c,
        896px 594px #ffb51c, 35px 893px #ffb51c, 895px 542px #ffb51c,
        706px 225px #ffb51c, 56px 1040px #ffb51c, 1954px 108px #ffb51c,
        1439px 1423px #ffb51c, 26px 1881px #ffb51c, 802px 1564px #ffb51c,
        273px 708px #ffb51c, 40px 31px #ffb51c, 859px 108px #ffb51c;
    }
  }

  &__star3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 940px 1360px #ffb51c, 1071px 539px #ffb51c,
      1710px 1414px #ffb51c, 836px 299px #ffb51c, 1944px 1420px #ffb51c,
      253px 1449px #ffb51c, 1257px 1250px #ffb51c, 1588px 1830px #ffb51c,
      1077px 1204px #ffb51c, 273px 1081px #ffb51c, 1993px 766px #ffb51c,
      1808px 479px #ffb51c, 917px 263px #ffb51c, 663px 1820px #ffb51c,
      342px 1988px #ffb51c, 727px 1250px #ffb51c, 636px 1666px #ffb51c,
      692px 1112px #ffb51c, 248px 1211px #ffb51c, 1422px 1121px #ffb51c,
      881px 46px #ffb51c, 1531px 1977px #ffb51c, 1643px 1023px #ffb51c,
      684px 1071px #ffb51c, 1142px 1873px #ffb51c, 292px 1313px #ffb51c,
      256px 1237px #ffb51c, 89px 912px #ffb51c, 964px 1783px #ffb51c,
      877px 760px #ffb51c, 1641px 1474px #ffb51c, 1492px 24px #ffb51c,
      1776px 1642px #ffb51c, 183px 602px #ffb51c, 1998px 62px #ffb51c,
      1560px 367px #ffb51c, 1333px 995px #ffb51c, 704px 1815px #ffb51c,
      1809px 712px #ffb51c, 1503px 288px #ffb51c, 630px 556px #ffb51c,
      1715px 125px #ffb51c, 353px 1878px #ffb51c, 975px 333px #ffb51c,
      1740px 1409px #ffb51c, 1341px 1871px #ffb51c, 1279px 1064px #ffb51c,
      169px 874px #ffb51c, 161px 528px #ffb51c, 1671px 1669px #ffb51c,
      169px 632px #ffb51c, 547px 1724px #ffb51c, 1904px 110px #ffb51c,
      679px 1670px #ffb51c, 196px 123px #ffb51c, 786px 871px #ffb51c,
      1840px 324px #ffb51c, 356px 967px #ffb51c, 61px 549px #ffb51c,
      99px 677px #ffb51c, 1719px 87px #ffb51c, 1713px 1990px #ffb51c,
      1717px 1358px #ffb51c, 108px 1187px #ffb51c, 51px 869px #ffb51c,
      1461px 902px #ffb51c, 1034px 891px #ffb51c, 962px 1881px #ffb51c,
      1723px 595px #ffb51c, 479px 901px #ffb51c, 1546px 1823px #ffb51c,
      285px 1208px #ffb51c, 1056px 347px #ffb51c, 261px 988px #ffb51c,
      466px 990px #ffb51c, 1657px 648px #ffb51c, 1249px 933px #ffb51c,
      1552px 1555px #ffb51c, 147px 62px #ffb51c, 292px 1157px #ffb51c,
      1816px 423px #ffb51c, 1714px 757px #ffb51c, 1036px 961px #ffb51c,
      1955px 710px #ffb51c, 1842px 516px #ffb51c, 479px 1870px #ffb51c,
      1579px 1445px #ffb51c, 1225px 1309px #ffb51c, 1965px 566px #ffb51c,
      1575px 1072px #ffb51c, 923px 329px #ffb51c, 651px 1514px #ffb51c,
      865px 1100px #ffb51c, 782px 1873px #ffb51c, 115px 299px #ffb51c,
      14px 1668px #ffb51c, 1666px 1817px #ffb51c, 1096px 1068px #ffb51c,
      1462px 742px #ffb51c, 1384px 1750px #ffb51c;
    -webkit-animation: animStar 150s linear infinite;
    -moz-animation: animStar 150s linear infinite;
    -ms-animation: animStar 150s linear infinite;
    animation: animStar 150s linear infinite;

    &:after {
      content: ' ';
      position: absolute;
      top: 2000px;
      width: 3px;
      height: 3px;
      background: transparent;
      box-shadow: 940px 1360px #ffb51c, 1071px 539px #ffb51c,
        1710px 1414px #ffb51c, 836px 299px #ffb51c, 1944px 1420px #ffb51c,
        253px 1449px #ffb51c, 1257px 1250px #ffb51c, 1588px 1830px #ffb51c,
        1077px 1204px #ffb51c, 273px 1081px #ffb51c, 1993px 766px #ffb51c,
        1808px 479px #ffb51c, 917px 263px #ffb51c, 663px 1820px #ffb51c,
        342px 1988px #ffb51c, 727px 1250px #ffb51c, 636px 1666px #ffb51c,
        692px 1112px #ffb51c, 248px 1211px #ffb51c, 1422px 1121px #ffb51c,
        881px 46px #ffb51c, 1531px 1977px #ffb51c, 1643px 1023px #ffb51c,
        684px 1071px #ffb51c, 1142px 1873px #ffb51c, 292px 1313px #ffb51c,
        256px 1237px #ffb51c, 89px 912px #ffb51c, 964px 1783px #ffb51c,
        877px 760px #ffb51c, 1641px 1474px #ffb51c, 1492px 24px #ffb51c,
        1776px 1642px #ffb51c, 183px 602px #ffb51c, 1998px 62px #ffb51c,
        1560px 367px #ffb51c, 1333px 995px #ffb51c, 704px 1815px #ffb51c,
        1809px 712px #ffb51c, 1503px 288px #ffb51c, 630px 556px #ffb51c,
        1715px 125px #ffb51c, 353px 1878px #ffb51c, 975px 333px #ffb51c,
        1740px 1409px #ffb51c, 1341px 1871px #ffb51c, 1279px 1064px #ffb51c,
        169px 874px #ffb51c, 161px 528px #ffb51c, 1671px 1669px #ffb51c,
        169px 632px #ffb51c, 547px 1724px #ffb51c, 1904px 110px #ffb51c,
        679px 1670px #ffb51c, 196px 123px #ffb51c, 786px 871px #ffb51c,
        1840px 324px #ffb51c, 356px 967px #ffb51c, 61px 549px #ffb51c,
        99px 677px #ffb51c, 1719px 87px #ffb51c, 1713px 1990px #ffb51c,
        1717px 1358px #ffb51c, 108px 1187px #ffb51c, 51px 869px #ffb51c,
        1461px 902px #ffb51c, 1034px 891px #ffb51c, 962px 1881px #ffb51c,
        1723px 595px #ffb51c, 479px 901px #ffb51c, 1546px 1823px #ffb51c,
        285px 1208px #ffb51c, 1056px 347px #ffb51c, 261px 988px #ffb51c,
        466px 990px #ffb51c, 1657px 648px #ffb51c, 1249px 933px #ffb51c,
        1552px 1555px #ffb51c, 147px 62px #ffb51c, 292px 1157px #ffb51c,
        1816px 423px #ffb51c, 1714px 757px #ffb51c, 1036px 961px #ffb51c,
        1955px 710px #ffb51c, 1842px 516px #ffb51c, 479px 1870px #ffb51c,
        1579px 1445px #ffb51c, 1225px 1309px #ffb51c, 1965px 566px #ffb51c,
        1575px 1072px #ffb51c, 923px 329px #ffb51c, 651px 1514px #ffb51c,
        865px 1100px #ffb51c, 782px 1873px #ffb51c, 115px 299px #ffb51c,
        14px 1668px #ffb51c, 1666px 1817px #ffb51c, 1096px 1068px #ffb51c,
        1462px 742px #ffb51c, 1384px 1750px #ffb51c;
    }
  }
}

@-webkit-keyframes animStar {
  from {
    -webkit-transform: translateY(0px);
  }
  to {
    -webkit-transform: translateY(-2000px);
  }
}

@-moz-keyframes animStar {
  from {
    -moz-transform: translateY(0px);
  }
  to {
    -moz-transform: translateY(-2000px);
  }
}

@-ms-keyframes animStar {
  from {
    -ms-transform: translateY(0px);
  }
  to {
    -ms-transform: translateY(-2000px);
  }
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
}
