@use '../../../styles/mixin' as *;
@use '../../../styles/variables' as *;

.contact-banner {
  overflow: hidden;

  &__inner {
    @include flex-layout(row, flex-start, flex-start);
    padding: 1.5rem 0;
    border-top: 1px solid $dark-background;
    border-bottom: 1px solid $dark-background;
  }

  &__content {
    @include flex-layout(row, space-around, center);
    position: relative;
    min-width: 100%;
  }

  &__description,
  &__link {
    @include header-typography;
    color: $dark-background;
    font-weight: 400;
    letter-spacing: -0.25vw;
    white-space: nowrap;

    @include desktop {
      font-size: 4vw;
    }
  }

  &__description {
    font-size: 4vw;
  }

  &__link {
    background-color: $dark-background;
    color: $primary-blue;
    padding: 0 0.5rem;
    transition: color 0.3s;
    letter-spacing: -0.1vw;
    font-size: 3vw;

    @include desktop {
      font-size: 3.5vw;
    }

    &:hover {
      color: $accent-color;
    }
  }
}
